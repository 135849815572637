// OurApproach.tsx

import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { motion } from "framer-motion";
import OpenContactButton from "./OpenContactButton";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

// Reusable Approach Card Component
const ApproachCard = ({
	icon,
	title,
	description,
	gradient,
}: {
	icon: React.ReactElement;
	title: string;
	description: string;
	gradient: string;
}) => (
	<Grid2 size={{ xs: 12, sm: 4 }}>
		<motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
			<Box
				className={`max-w-sm h-full shadow-lg rounded-lg overflow-hidden flex flex-col`}
				sx={{
					transition: "transform 0.3s ease-in-out",
					minHeight: { sm: "600px" },
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: 4,
					background:
						"linear-gradient(135deg, #3c4a9e 0%, #4d2b69 100%)",
				}}
			>
				<Box className="p-4 flex flex-col items-center flex-grow">
					<Box sx={{ mb: 4 }}>{icon}</Box>
					<Typography
						variant="h5"
						sx={{
							fontWeight: 600,
							marginBottom: "0.5rem",
							color: "white",
						}}
					>
						{title}
					</Typography>
					<Typography
						variant="body1"
						sx={{
							color: "white",
						}}
					>
						{description}
					</Typography>
				</Box>
			</Box>
		</motion.div>
	</Grid2>
);

const OurApproach = () => (
	<Box
		sx={{
			minHeight: {
				xs: "100vh",
				sm: "100vh",
				md: "100vh",
			},
			background: "black",
			display: "flex",
			alignItems: "center",
			width: "100%",
			px: { xs: 2, md: 8 }, // Smaller padding on mobile
			py: { xs: 8, md: 8, lg: 12 }, // Vertical padding for better spacing
			borderTop: "0.5px solid gray",
		}}
	>
		<Box sx={{ width: "100%" }}>
			<Grid2 container alignItems="center" spacing={4}>
				{/* Left Section with Header */}
				<Grid2
					size={{ xs: 12, lg: 3 }}
					display="flex"
					sx={{
						justifyContent: {
							xs: "center",
							lg: "flex-start",
						},
						height: {
							lg: "60vh",
						},
					}}
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1.5 }}
					>
						<Typography
							variant="h2"
							sx={{
								color: "white",
								fontWeight: "bold",
								textAlign: "left",
							}}
						>
							Our Approach
						</Typography>
					</motion.div>
				</Grid2>

				{/* Cards Section */}
				<Grid2 size={{ xs: 12, lg: 9 }}>
					<Grid2 container spacing={4} justifyContent="space-around">
						<ApproachCard
							icon={
								<TipsAndUpdatesIcon
									sx={{ fontSize: 64, color: "white" }}
								/>
							}
							title="INNOVATIVE DESIGN"
							description="Our team works closely with you to design cutting-edge, user-centric solutions that align with your business goals. We take pride in every solution we craft, ensuring it is both beautiful and functional."
							gradient="bg-gradient-to-r from-purple-200 to-purple-400"
						/>
						<ApproachCard
							icon={
								<AssignmentTurnedInIcon
									sx={{ fontSize: 64, color: "white" }}
								/>
							}
							title="STRATEGIC PLANNING"
							description="We meticulously plan each phase of the project, ensuring we understand every aspect of your needs. Our strategic approach ensures that we deliver solutions that empower businesses to establish a strong digital presence and scale with confidence."
							gradient="bg-gradient-to-r from-purple-300 to-purple-500"
						/>
						<ApproachCard
							icon={
								<RocketLaunchIcon
									sx={{ fontSize: 64, color: "white" }}
								/>
							}
							title="SMART EXECUTION"
							description="From ideation to deployment, we smartly execute projects by collaborating with you at every step. Our team ensures that we deliver high-quality, scalable solutions that help turn your business dreams into reality."
							gradient="bg-gradient-to-r from-purple-400 to-purple-600"
						/>
					</Grid2>
					<Grid2
						container
						sx={{
							py: 12,
							justifyContent: "center",
							alignItems: "center",
						}}
						size={{ xs: 12 }}
					>
						<OpenContactButton
							color="white"
							label="Let’s Build Something Amazing Together"
							variant="contained"
							backgroundColor="#4d40f4"
							size="large"
						/>
					</Grid2>
				</Grid2>
			</Grid2>
		</Box>
	</Box>
);

export default OurApproach;
