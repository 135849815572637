// OpenContactButton.tsx

import React, { useState } from "react";
import { Button } from "@mui/material";
import ContactDialog from "./ContactDialog";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RECAPTCHA_SITE_KEY = "6LecY18qAAAAALZzIgqTT-8rHEe0ziAHFLGCIMG0";
// const RECAPTCHA_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // Test Key

interface OpenContactButtonProps {
	color?: string;
	label?: string;
	backgroundColor?: string;
	variant?: "text" | "outlined" | "contained";
	size?: "small" | "medium" | "large";
}

const OpenContactButton: React.FC<OpenContactButtonProps> = ({
	color = "black",
	label = "Contact Us",
	variant = "text",
	backgroundColor = "primary",
	size = "medium",
}) => {
	const [isDialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);
	return (
		<>
			<Button
				variant={variant}
				size={size}
				color="primary"
				onClick={handleOpenDialog}
				sx={{ color: color, backgroundColor: backgroundColor }}
			>
				{label}
			</Button>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
				<ContactDialog
					open={isDialogOpen}
					handleClose={handleCloseDialog}
				/>
			</GoogleReCaptchaProvider>
		</>
	);
};

export default OpenContactButton;
