// DevOpsBlob.tsx

import React, { useRef } from "react";
import { Box } from "@mui/material";
import { motion, useInView } from "framer-motion";

const circleStyle: React.CSSProperties = {
	width: 150,
	height: 150,
	borderRadius: "50%",
	backgroundColor: "#4d40f4", // Bright teal for DevOps
	position: "absolute",
};

const containerStyle: React.CSSProperties = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
	overflow: "hidden",
	height: 500,
	width: 500,
};

const DevOpsBlob: React.FC = () => {
	const ref = useRef(null);
	const isInView = useInView(ref);

	// Initial positions for the 4 circles at the corners of the square
	const initialPositions = [
		{ x: -100, y: -100 }, // Top-left
		{ x: 100, y: -100 }, // Top-right
		{ x: -100, y: 100 }, // Bottom-left
		{ x: 100, y: 100 }, // Bottom-right
	];

	// Final positions for 8 outer circles + 1 center circle
	const finalPositions = [
		{ x: -140, y: -140 }, // Top-left
		{ x: 140, y: -140 }, // Top-right
		{ x: -140, y: 140 }, // Bottom-left
		{ x: 140, y: 140 }, // Bottom-right
		{ x: 0, y: -140 }, // Top-center
		{ x: 0, y: 140 }, // Bottom-center
		{ x: -140, y: 0 }, // Left-center
		{ x: 140, y: 0 }, // Right-center
		{ x: 0, y: 0 }, // Center
	];

	return (
		<div style={{ height: "500px" }}>
			<Box ref={ref} style={containerStyle}>
				{/* Initial 4 Circles */}
				{initialPositions.map((pos, index) => (
					<motion.div
						key={index}
						style={circleStyle}
						initial={{ x: pos.x, y: pos.y }}
						animate={
							isInView
								? { x: 0, y: 0 } // Move to the center
								: { x: pos.x, y: pos.y }
						}
						transition={{ duration: 1.5, delay: index * 0.2 }}
					/>
				))}

				{/* Final 9 Circles */}
				{finalPositions.map((pos, index) => (
					<motion.div
						key={`final-${index}`}
						style={{ ...circleStyle, width: 150, height: 150 }}
						initial={{ opacity: 0 }}
						animate={
							isInView
								? { opacity: 1, x: pos.x, y: pos.y }
								: { opacity: 0 }
						}
						transition={{ duration: 1.5, delay: 1 }}
					/>
				))}
			</Box>
		</div>
	);
};

export default DevOpsBlob;
