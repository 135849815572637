import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { Box } from "@mui/material";

const getSquareSize = (): number => {
	// Use window.innerWidth to adjust size based on screen width
	const screenWidth = window.innerWidth;
	if (screenWidth < 600) {
		return 100; // Small screens
	} else if (screenWidth < 900) {
		return 125; // Medium screens
	} else if (screenWidth < 1200) {
		return 140; // Large screens
	} else if (screenWidth < 1536) {
		return 175;
	} else {
		return 200;
	}
};

const getPosition = (): number => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 600) {
		return 100; // Small screens
	} else if (screenWidth < 900) {
		return 125; // Medium screens
	} else if (screenWidth < 1200) {
		return 140; // Large screens
	} else if (screenWidth < 1536) {
		return 175;
	} else {
		return 200;
	}
};

const HeroSectionBlob: React.FC = () => {
	const ref = useRef(null); // Reference to the section
	const isInView = useInView(ref);
	const squareSize = getSquareSize(); // Dynamically get square size
	const animationWidth = getPosition();

	// Define corner positions for squares
	const positions = [
		{ x: -animationWidth, y: -animationWidth },
		{ x: animationWidth, y: -animationWidth },
		{ x: -animationWidth, y: animationWidth },
		{ x: animationWidth, y: animationWidth },
	];

	const squareStyle: React.CSSProperties = {
		width: squareSize,
		height: squareSize,
		backgroundColor: "white",
		borderRadius: 16,
		position: "absolute",
	};

	return (
		<Box
			ref={ref}
			sx={{
				display: "flex",
				alignItems: {
					xs: "flex-start",
					lg: "center",
				},
				justifyContent: "center",
				height: { xs: "30vh", lg: "100vh" },
				width: "100vw",
				position: "relative",
			}}
		>
			{positions.map((pos, index) => (
				<motion.div
					key={index}
					style={squareStyle}
					initial={{ x: 0, y: 0, rotate: 0 }}
					animate={
						isInView
							? {
									x: [0, pos.x, 0], // Move out and back to center
									y: [0, pos.y, 0], // Same for y
									rotate: [0, 180, 360], // Rotate while moving
							  }
							: { x: 0, y: 0, rotate: 0 }
					}
					transition={{
						duration: 4,
						repeat: Infinity,
						repeatType: "loop", // Smooth loop
						ease: "easeInOut",
					}}
				/>
			))}
		</Box>
	);
};

export default HeroSectionBlob;
