import { Box, Grid2, Typography } from "@mui/material";
import { motion } from "framer-motion";
import HeroSectionBlob from "./HeroSectionBlob";

const HeroSection = () => {
	return (
		<Box
			sx={{
				minHeight: {
					xs: "100vh",
					sm: "100vh",
					md: "100vh",
				},
				background: "linear-gradient(135deg, #3c4a9e 0%, #4d2b69 100%)",
				display: "flex",
				alignItems: "center",
				width: "100%",
				px: 8,
			}}
		>
			<Box sx={{ width: "100%" }}>
				<Grid2
					size={{ xs: 12, md: 5 }}
					sx={{ display: { xs: "block", lg: "none" } }}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
						}}
					>
						<HeroSectionBlob />
					</Box>
				</Grid2>
				<Grid2 container alignItems="center">
					{/* Left Section with text */}
					<Grid2 size={{ xs: 12, lg: 7 }}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.5 }}
						>
							<Typography
								variant="h1"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								Innovating the Future of Technology
							</Typography>
							<Typography
								variant="h4"
								sx={{
									color: "white",
									textAlign: "left",
									marginTop: 6,
								}}
							>
								We build digital solutions that empower
								businesses to scale with confidence, using
								cutting-edge technology and AI-driven
								innovation.
							</Typography>
						</motion.div>
					</Grid2>

					{/* Right Section with SVG animation */}
					<Grid2
						size={{ xs: 12, md: 5 }}
						sx={{ display: { xs: "none", lg: "block" } }}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<HeroSectionBlob />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default HeroSection;
