import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion, AnimatePresence } from "framer-motion";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Define props if you want to pass images dynamically
interface CarouselGridProps {
	images: string[];
}

const CarouselGrid: React.FC<CarouselGridProps> = ({ images }) => {
	const [selectedImage, setSelectedImage] = useState<string | null>(null);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1024 },
			items: 4,
		},
		desktop: {
			breakpoint: { max: 1024, min: 768 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 768, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<div className="w-full h-full relative">
			{/* Carousel with Images */}
			{!selectedImage && ( // Hide the carousel when an image is opened
				<Carousel
					responsive={responsive}
					infinite
					autoPlay
					slidesToSlide={1}
					swipeable
					showDots
					centerMode={false}
					itemClass=" content-center"
				>
					{images.map((src, index) => (
						<motion.div
							key={index}
							whileHover={{ scale: 1.1 }}
							onClick={() => setSelectedImage(src)}
							className="cursor-pointer p-2 flex flex-col justify-center items-center content-center"
						>
							<img
								src={src}
								alt={`thumbnail-${index}`}
								className="w-full h-auto"
								style={{
									objectFit: "contain",
									maxHeight: "400px",
								}}
							/>
						</motion.div>
					))}
				</Carousel>
			)}

			{/* Fullscreen Modal for Selected Image */}
			<AnimatePresence>
				{selectedImage && (
					<motion.div
						className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-1000 pt-16 px-5"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<div className="relative flex justify-center items-center w-full h-full">
							{/* Close Button */}
							<IconButton
								aria-label="close"
								sx={{
									position: "absolute",
									top: 20,
									right: 20,
									color: "white",
									zIndex: 1000,
								}}
								onClick={() => setSelectedImage(null)}
							>
								<CloseIcon fontSize="large" />
							</IconButton>

							{/* Fullscreen Image */}
							<motion.img
								src={selectedImage}
								alt="fullscreen"
								className="max-w-full max-h-full"
								initial={{ scale: 0.8 }}
								animate={{ scale: 1 }}
								exit={{ scale: 0.8 }}
								transition={{ duration: 0.3 }}
								style={{ objectFit: "contain" }}
							/>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default CarouselGrid;
