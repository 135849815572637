import { motion } from "framer-motion";

import { Box, Grid2, Typography } from "@mui/material";
import FoodBlogExplore from "../assets/images/screens/foodblog/explore.png";
import FoodBlogFavorites from "../assets/images/screens/foodblog/favorites.png";
import FoodBlogRecipe from "../assets/images/screens/foodblog/recipe.png";
import FoodBlogSearch from "../assets/images/screens/foodblog/search.png";
import TarzanExplore from "../assets/images/screens/tarzan/explore.png";
import TarzanFilter from "../assets/images/screens/tarzan/filter.png";
import TarzanSearch from "../assets/images/screens/tarzan/search.png";
import TarzanRoom from "../assets/images/screens/tarzan/room.png";
import TheDesignTeamWebHome from "../assets/images/screens/thedesignteam/web_home.png";
import TheDesignTeamWebHowWeWork from "../assets/images/screens/thedesignteam/web_how_we_work.png";
import TheDesignTeamWebWhoWeAre from "../assets/images/screens/thedesignteam/web_who_we_are.png";
import TheDesignTeamWebOurWork from "../assets/images/screens/thedesignteam/web_our_work.png";
import TheDesignTeamMobileHome from "../assets/images/screens/thedesignteam/mobile_home.png";
import TheDesignTeamMobileOurWork from "../assets/images/screens/thedesignteam/mobile_our_work.png";

import CarouselGrid from "./CarouselGrid";

const images = [
	FoodBlogExplore,
	FoodBlogFavorites,
	FoodBlogSearch,
	FoodBlogRecipe,
	TarzanSearch,
	TarzanFilter,
	TarzanExplore,
	TarzanRoom,
	TheDesignTeamWebHome,
	TheDesignTeamWebHowWeWork,
	TheDesignTeamWebWhoWeAre,
	TheDesignTeamWebOurWork,
	TheDesignTeamMobileHome,
	TheDesignTeamMobileOurWork
];

export const Portfolio = () => {
	return (
		<Box
			sx={{
				minHeight: {
					xs: "100vh",
					sm: "100vh",
					md: "100vh",
				},
				background: "black",
				display: "flex",
				alignItems: "center",
				width: "100vw",
				px: 8,
				borderTopColor: "gray",
				borderTopWidth: 0.5,
			}}
		>
			<Box sx={{ width: "100%" }}>
				<Grid2 container alignItems="center">
					{/* Left Section with text */}
					<Grid2 size={{ xs: 12, md: 3 }}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.5 }}
						>
							<Typography
								variant="h2"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								Portfolio
							</Typography>
						</motion.div>
					</Grid2>

					{/* Right Section with more descriptive text */}
					<Grid2 size={{ xs: 12, md: 9 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								textAlign: "center",
								px: { xs: 2, md: 6 },
							}}
						>
							<CarouselGrid images={images} />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};
