import { Box, Grid2, Typography } from "@mui/material";
import { motion } from "framer-motion";
import WebDebBlob from "./WebDebBlob";

const WebMobileDevelopment = () => {
	return (
		<Box
			sx={{
				minHeight: {
					xs: "100vh",
					sm: "100vh",
					md: "100vh",
				},
				background: "black",
				display: "flex",
				alignItems: "center",
				width: "100%",
				px: 8,
				borderTopColor: "gray",
				borderTopWidth: 0.5,
			}}
		>
			<Box sx={{ width: "100%" }}>
				{/* <Grid2
					size={{ xs: 12, md: 7 }}
					sx={{ display: { xs: "block", lg: "none" } }}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
							paddingBottom: 4,
						}}
					>
						<WebDebBlob />
					</Box>
				</Grid2> */}
				<Grid2 container alignItems="center">
					{/* Left Section with text */}
					<Grid2 size={{ xs: 12, lg: 7 }}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.5 }}
						>
							<Typography
								variant="h2"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								Web & Mobile Development
							</Typography>
							<Typography
								variant="h5"
								sx={{
									color: "white",
									fontWeight: "500",
									textAlign: "left",
									py: 1,
								}}
							>
								Your audience is already online. A well-crafted
								website isn’t just a digital presence—it’s your
								brand’s showcase, your storefront, and your
								first impression. At QuickLoop, our experienced
								web designers specialize in building custom
								websites that captivate, convert, and leave a
								lasting impact. Let’s create something
								extraordinary together.
							</Typography>
							<Box sx={{ marginTop: 2 }}>
								<Grid2 container spacing={2}>
									{/* First Column */}
									<Grid2 size={{ xs: 6 }}>
										<Typography
											variant="body1"
											sx={{ color: "white" }}
										>
											Responsive Web Design
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											E-commerce Solutions
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Content Management Systems
										</Typography>
									</Grid2>

									{/* Second Column */}
									<Grid2 size={{ xs: 6 }}>
										<Typography
											variant="body1"
											sx={{ color: "white" }}
										>
											Cross-platform Apps
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											AI-driven Solutions
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Progressive Web Apps
										</Typography>
									</Grid2>
								</Grid2>
							</Box>
						</motion.div>
					</Grid2>

					{/* Right Section with SVG animation */}
					<Grid2
						size={{ xs: 12, md: 5 }}
						sx={{ display: { xs: "none", lg: "block" } }}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<WebDebBlob />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default WebMobileDevelopment;
