// src/components/Footer.tsx

import React from "react";
import { Box, Grid2, Typography, IconButton, Button } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import quickloopLogoDark from "../assets/logos/quickloop-dark.svg";
import OpenContactButton from "./OpenContactButton";

const Footer = () => {
	const scrollToSection = (id: string) => {
		const section = document.querySelector(id);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<footer
			style={{
				backgroundColor: "#000",
				color: "white",
				padding: "2rem 0",
				width: "100%",
				borderTop: "0.5px solid gray",
			}}
		>
			<Box sx={{ px: { xs: 2, md: 10 } }}>
				<Grid2 container spacing={4} justifyContent="space-evenly">
					{/* Logo and Tagline */}
					<Grid2
						size={{ xs: 12, sm: 4 }}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: { xs: "center", md: "flex-start" },
							textAlign: { xs: "center", md: "left" },
						}}
					>
						<a href="/" className="navbar-brand">
							<img
								src={quickloopLogoDark}
								alt="Quickloop"
								className="navbar-logo"
								style={{ height: "80px" }}
							/>
						</a>

						<Typography variant="body2" sx={{ marginTop: 1 }}>
							Empowering your business with cutting-edge
							technology solutions.
						</Typography>
					</Grid2>

					{/* Quick Links */}
					<Grid2
						size={{ xs: 12, sm: 4 }}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							variant="h6"
							sx={{ fontWeight: "bold", marginBottom: 1 }}
						>
							Quick Links
						</Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
							}}
						>
							<Button
								color="inherit"
								onClick={() => scrollToSection("#home")}
							>
								Home
							</Button>
							<Button
								color="inherit"
								onClick={() => scrollToSection("#services")}
							>
								Services
							</Button>
							<Button
								color="inherit"
								onClick={() => scrollToSection("#team")}
							>
								Team
							</Button>
							<OpenContactButton color="white" />
						</Box>
					</Grid2>

					{/* Social Media and Contact */}
					<Grid2
						size={{ xs: 12, sm: 4 }}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							variant="h6"
							sx={{ fontWeight: "bold", marginBottom: 1 }}
						>
							Stay Connected
						</Typography>
						<Box sx={{ display: "flex", gap: 2 }}>
							<IconButton
								href="https://www.linkedin.com/company/quickloop"
								target="_blank"
								color="inherit"
							>
								<LinkedInIcon />
							</IconButton>
							<IconButton
								href="mailto:contact@quickloop.co"
								color="inherit"
							>
								<EmailIcon />
							</IconButton>
						</Box>
					</Grid2>
				</Grid2>

				{/* Footer Bottom Section */}
				<Box
					sx={{
						textAlign: "center",
						marginTop: 3,
						borderTop: "1px solid #444",
						paddingTop: 2,
					}}
				>
					<Typography variant="body2">
						© {new Date().getFullYear()} QuickLoop. All rights
						reserved.
					</Typography>
					<Typography variant="body2" sx={{ marginTop: 1 }}>
						Designed with passion by QuickLoop ❤️
					</Typography>
				</Box>
			</Box>
		</footer>
	);
};

export default Footer;
