// theme.ts
import { createTheme } from "@mui/material/styles";

const baseTheme = createTheme(); // Step 1: Create the base theme

const theme = createTheme({
	typography: {
		fontFamily: "Lexend, serif", // Global font setting
		h1: {
			fontFamily: "Lexend, serif",
			fontWeight: 700,
			fontSize: "2rem", // Default for xs
			[baseTheme.breakpoints.up("sm")]: {
				fontSize: "3rem",
			},
			[baseTheme.breakpoints.up("md")]: {
				fontSize: "3.5rem",
			},
			[baseTheme.breakpoints.up("lg")]: {
				fontSize: "5rem",
			},
		},
		h2: {
			fontFamily: "Lexend, serif",
			fontWeight: 700,
			fontSize: "1.75rem", // Default for xs
			[baseTheme.breakpoints.up("sm")]: {
				fontSize: "2rem",
			},
			[baseTheme.breakpoints.up("md")]: {
				fontSize: "3rem",
			},
			[baseTheme.breakpoints.up("lg")]: {
				fontSize: "4rem",
			},
		},
		h3: {
			fontFamily: "Lexend, serif",
			fontWeight: 700,
		},
		h4: {
			fontFamily: "Lexend, serif",
			fontWeight: 300,
			fontSize: "1rem", // Default for xs
			[baseTheme.breakpoints.up("sm")]: {
				fontSize: "1.5rem",
			},
			[baseTheme.breakpoints.up("md")]: {
				fontSize: "1.75rem",
			},
			[baseTheme.breakpoints.up("lg")]: {
				fontSize: "2.125rem",
			},
		},
		h5: {
			fontFamily: "Lexend, serif",
			fontWeight: 300,
			fontSize: "1rem", // Default for xs
			[baseTheme.breakpoints.up("sm")]: {
				fontSize: "1rem",
			},
			[baseTheme.breakpoints.up("md")]: {
				fontSize: "1rem",
			},
			[baseTheme.breakpoints.up("lg")]: {
				fontSize: "1.25rem",
			},
		},
		h6: {
			fontFamily: "Lexend, serif",
			fontWeight: 300,
			fontSize: "0.75rem", // Default for xs
			[baseTheme.breakpoints.up("sm")]: {
				fontSize: "0.75rem",
			},
			[baseTheme.breakpoints.up("md")]: {
				fontSize: "1rem",
			},
			[baseTheme.breakpoints.up("lg")]: {
				fontSize: "1.25rem",
			},
		},
		body1: {
			fontFamily: "Lexend, serif",
			fontWeight: 300,
			fontSize: "1.25rem", // Default for xs
			[baseTheme.breakpoints.up("sm")]: {
				fontSize: "1.25rem",
			},
			[baseTheme.breakpoints.up("md")]: {
				fontSize: "1.25rem",
			},
			[baseTheme.breakpoints.up("lg")]: {
				fontSize: "1.25rem",
			},
		},
		body2: {
			fontFamily: "Lexend, serif",
			fontWeight: 300,
		},
	},
});

export default theme;
