// DevOps.tsx

import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { motion } from "framer-motion";
import DevOpsBlob from "./DevOpsBlob"; // Assume you have an SVG for DevOps

const DevOpsComponent = () => {
	return (
		<Box
			sx={{
				minHeight: {
					xs: "100vh",
					sm: "100vh",
					md: "100vh",
				},
				background: "black",
				display: "flex",
				alignItems: "center",
				width: "100%",
				px: 8,
				borderTopColor: "gray",
				borderTopWidth: 0.5,
			}}
		>
			<Box sx={{ width: "100%" }}>
				<Grid2 container alignItems="center">
					{/* Left Section with text */}
					<Grid2 size={{ xs: 12, md: 5 }}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.5 }}
						>
							<Typography
								variant="h2"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								DevOps & Cloud Solutions
							</Typography>
							<Typography
								variant="h5"
								sx={{
									color: "white",
									fontWeight: "500",
									textAlign: "left",
									py: 1,
								}}
							>
								Empower your business with seamless DevOps and
								cloud infrastructure. At QuickLoop, we help
								automate deployments, enhance scalability, and
								ensure high availability. Our experienced DevOps
								engineers design reliable pipelines and cloud
								solutions that streamline your workflows and
								drive efficiency. Let’s accelerate your growth
								together.
							</Typography>
							<Box sx={{ marginTop: 2 }}>
								<Grid2 container spacing={2}>
									{/* First Column */}
									<Grid2 size={{ xs: 6 }}>
										<Typography
											variant="body1"
											sx={{ color: "white" }}
										>
											CI/CD Pipelines
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Cloud Infrastructure Management
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Containerization (Docker &
											Kubernetes)
										</Typography>
									</Grid2>

									{/* Second Column */}
									<Grid2 size={{ xs: 6 }}>
										<Typography
											variant="body1"
											sx={{ color: "white" }}
										>
											Monitoring & Logging
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Automation & Configuration
											Management
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Cloud Migration & Security
										</Typography>
									</Grid2>
								</Grid2>
							</Box>
						</motion.div>
					</Grid2>

					{/* Right Section with SVG animation */}
					<Grid2
						size={{ xs: 12, md: 7 }}
						sx={{ display: { xs: "none", md: "block" } }}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<DevOpsBlob />{" "}
							{/* Placeholder for your DevOps SVG */}
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default DevOpsComponent;
