// App.tsx

import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { Box } from "@mui/material";

const getCircleDiameter = (): number => {
	// Use window.innerWidth to adjust size based on screen width
	const screenWidth = window.innerWidth;
	if (screenWidth < 600) {
		return 100; // Small screens
	} else if (screenWidth < 900) {
		return 125; // Medium screens
	} else if (screenWidth < 1200) {
		return 140; // Large screens
	} else if (screenWidth < 1536) {
		return 175;
	} else {
		return 200;
	}
};

const getPosition = (): number => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 600) {
		return 50; // Small screens
	} else if (screenWidth < 900) {
		return 125; // Medium screens
	} else if (screenWidth < 1200) {
		return 140; // Large screens
	} else if (screenWidth < 1536) {
		return 175;
	} else {
		return 200;
	}
};

const WebDebBlob: React.FC = () => {
	const ref = useRef(null); // Reference to the section
	const isInView = useInView(ref);
	const circleDiameter = getCircleDiameter(); // Dynamically get square size
	const animationWidth = getPosition();

	const circleStyle: React.CSSProperties = {
		width: circleDiameter,
		height: circleDiameter,
		borderRadius: "50%",
		backgroundColor: "#4d40f4",
		position: "absolute",
	};

	return (
		<Box
			ref={ref}
			sx={{
				display: "flex",
				alignItems: {
					xs: "center",
					lg: "center",
				},
				justifyContent: "center",
				height: { xs: "30vh", lg: "100vh" },
				width: "100vw",
				position: "relative",
			}}
		>
			{/* Circles */}
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={
					isInView
						? {
								x: [0, -animationWidth, 0],
								y: [0, -animationWidth, 0],
						  }
						: { x: 0, y: 0 }
				}
				transition={{
					duration: 4,
					repeat: Infinity,
					repeatType: "loop",
				}}
			/>
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={
					isInView
						? {
								x: [0, animationWidth, 0],
								y: [0, -animationWidth, 0],
						  }
						: { x: 0, y: 0 }
				}
				transition={{
					duration: 4,
					repeat: Infinity,
					repeatType: "loop",
				}}
			/>
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={
					isInView
						? {
								x: [0, -animationWidth, 0],
								y: [0, animationWidth, 0],
						  }
						: { x: 0, y: 0 }
				}
				transition={{
					duration: 4,
					repeat: Infinity,
					repeatType: "loop",
				}}
			/>
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={
					isInView
						? {
								x: [0, animationWidth, 0],
								y: [0, animationWidth, 0],
						  }
						: { x: 0, y: 0 }
				}
				transition={{
					duration: 4,
					repeat: Infinity,
					repeatType: "loop",
				}}
			/>
		</Box>
	);
};

export default WebDebBlob;
