import React, { useState, useEffect, ReactNode } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import WebMobileDevelopment from "./components/WebMobileDevelopment";
import Footer from "./components/Footer";
import AiDevelopment from "./components/AiDevelopment";
import DevOpsComponent from "./components/DevOpsComponent";
import MeetTheTeam from "./components/MeetTheTeam";
import OurApproach from "./components/OurApproach";
import { Portfolio } from "./components/Portfolio";

interface PageProps {
	id?: string;
	children: ReactNode;
}

const Page: React.FC<PageProps> = ({ id, children }) => (
	<section
		id={id}
		className="w-full flex items-center justify-center snap-start"
	>
		{children}
	</section>
);

const App: React.FC = () => {
	const [currentPage, setCurrentPage] = useState<number>(0);

	useEffect(() => {
		const handleScroll = (): void => {
			const scrollPosition = window.scrollY;
			const windowHeight = window.innerHeight;
			const currentPage = Math.round(scrollPosition / windowHeight);
			setCurrentPage(currentPage);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const scrollTo = (page: number): void => {
		window.scrollTo({
			top: page * window.innerHeight,
			behavior: "smooth",
		});
	};

	return (
		<div className="App">
			{/* Render the Header */}
			<Header />

			{/* Add padding to prevent content overlap with the fixed Header */}
			<div
				className="overflow-y-scroll snap-y snap-mandatory h-screen"
				style={{ paddingTop: "64px" }}
			>
				<Page id="home">
					<HeroSection />
				</Page>
				<Page id="services">
					<WebMobileDevelopment />
				</Page>
				<Page>
					<AiDevelopment />
				</Page>
				<Page>
					<DevOpsComponent />
				</Page>
				<Page id="our-approach">
					<OurApproach />
				</Page>
				<Page id="portfolio">
					<Portfolio />
				</Page>
				<Page id="team">
					<MeetTheTeam />
				</Page>
				<Page>
					<Footer />
				</Page>
			</div>
		</div>
	);
};

export default App;
