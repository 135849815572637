import {
	Box,
	Typography,
	Grid2,
	Card,
	CardContent,
	IconButton,
	CardMedia,
	Link,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import gunjan from "../assets/images/co-founders/gunjan.jpeg";
import saurabh from "../assets/images/co-founders/saurabh.jpeg";
import varun from "../assets/images/co-founders/varun.jpeg";

const teamData = [
	{
		name: "Gunjan Malani",
		position: "CMO, Co-Founder",
		bio: "Full-stack developer with 8+ years of experience in web and cloud solutions.",
		photo: gunjan,
		linkedin: "https://www.linkedin.com/in/gunjan-malani/",
	},
	{
		name: "Saurabh Pabhare",
		position: "Co-Founder",
		bio: "Creative designer with a passion for intuitive user experiences.",
		photo: saurabh,
		linkedin: "https://www.linkedin.com/in/saurabh-pabhare/",
	},
	{
		name: "Varun Rathore",
		position: "Co-Founder",
		bio: "Expert in CI/CD pipelines and cloud infrastructure management.",
		photo: varun,
		linkedin: "https://www.linkedin.com/in/varun-rathore/",
	},
];

const MeetTheTeam = () => {
	return (
		<Box
			sx={{
				flex: 1,
				minHeight: {
					xs: "100vh",
					sm: "100vh",
					md: "100vh",
				},
				background: "black",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				px: { xs: 2, md: 8 }, // Responsive padding
				py: { xs: 8, md: 8 },
				borderTop: "0.5px solid gray",
			}}
		>
			<Grid2
				container
				spacing={2}
				justifyContent="center"
				alignItems="center"
				className="w-full"
			>
				<Grid2 size={{ xs: 12, sm: 4 }}>
					<Typography
						variant="h2"
						sx={{
							textAlign: "center",
							mb: 4,
							fontWeight: "bold",
							color: "white",
						}}
					>
						Meet Our Team
					</Typography>
				</Grid2>
				<Grid2
					size={{ xs: 12, sm: 8 }}
					display="flex"
					sx={{
						display: "flex",
						flexDirection: { xs: "column", sm: "row" },
						justifyContent: { sm: "space-evenly" },
						flex: { sm: 1 },
					}}
				>
					{teamData.map((member, index) => (
						<Grid2
							key={index}
							size={{ xs: 12, sm: 3, md: 3 }}
							sx={{
								px: { xs: 2, md: "unset" }, // Responsive padding
								py: { xs: 4, md: "unset" },
							}}
						>
							<Card
								sx={{
									maxWidth: {
										xs: 300,
										md: 400,
									},
									margin: "0 auto",
									boxShadow: 3,
									borderRadius: 2,
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
							>
								<CardMedia
									component="img"
									image={member.photo}
									alt={member.name}
									sx={{
										height: 300,
										objectFit: "cover",
									}}
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography
										variant="h5"
										align="center"
										fontWeight="bold"
										gutterBottom
									>
										{member.name}
									</Typography>
									<Typography
										variant="subtitle1"
										align="center"
										color="textSecondary"
									>
										{member.position}
									</Typography>

									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											marginTop: 1,
										}}
									>
										<Link
											href={member.linkedin}
											target="_blank"
											rel="noopener noreferrer"
										>
											<IconButton color="primary">
												<LinkedInIcon />
											</IconButton>
										</Link>
									</Box>
								</CardContent>
							</Card>
						</Grid2>
					))}
				</Grid2>
			</Grid2>
		</Box>
	);
};

export default MeetTheTeam;
