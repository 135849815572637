import React, { useRef } from "react";
import { Box } from "@mui/material";
import { motion, useInView } from "framer-motion";

const getCircleDiameter = (): number => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 600) {
		return 72; // Small screens
	} else if (screenWidth < 900) {
		return 140; // Medium screens
	} else if (screenWidth < 1200) {
		return 140; // Large screens
	} else {
		return 180; // Extra-large screens
	}
};

const getAnimationDistance = (): number => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 600) {
		return 80; // Small screens
	} else if (screenWidth < 900) {
		return 170; // Medium screens
	} else if (screenWidth < 1200) {
		return 170; // Large screens
	} else {
		return 250; // Extra-large screens
	}
};

const AiBlob: React.FC = () => {
	const ref = useRef(null);
	const isInView = useInView(ref);
	const circleDiameter = getCircleDiameter();
	const animationDistance = getAnimationDistance();

	const circleStyle: React.CSSProperties = {
		width: circleDiameter,
		height: circleDiameter,
		borderRadius: "50%",
		backgroundColor: "black",
		position: "absolute",
	};

	const initialPositions = [
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
	];

	const finalPositions = [
		{ x: -animationDistance, y: -animationDistance }, // Top-left
		{ x: 0, y: -animationDistance }, // Top-center
		{ x: animationDistance, y: -animationDistance }, // Top-right
		{ x: -animationDistance, y: animationDistance }, // Bottom-left
		{ x: 0, y: animationDistance }, // Bottom-center
		{ x: animationDistance, y: animationDistance }, // Bottom-right
		{ x: -animationDistance, y: 0 }, // Left-center
		{ x: animationDistance, y: 0 }, // Right-center
		{ x: 0, y: 0 }, // Center
	];

	return (
		<Box
			ref={ref}
			sx={{
				display: "flex",
				alignItems: {
					xs: "center",
					lg: "center",
				},
				justifyContent: "center",
				height: { xs: "250px", sm: "500px", lg: "400px", xl: "500px" },
				width: { xs: "250px", sm: "500px", lg: "400px", xl: "500px" },
				position: "relative",
				background: "linear-gradient(135deg, #3c4a9e 0%, #4d2b69 100%)",
				overflow: "hidden",
			}}
		>
			{/* Initial 4 Circles */}
			{initialPositions.map((pos, index) => (
				<motion.div
					key={index}
					style={circleStyle}
					initial={{ x: 0, y: 0 }}
					animate={
						isInView
							? {
									x: [0, finalPositions[index].x, 0],
									y: [0, finalPositions[index].y, 0],
							  }
							: { x: 0, y: 0 }
					}
					transition={{
						duration: 4,
						repeat: Infinity,
						repeatType: "loop",
					}}
				/>
			))}
		</Box>
	);
};

export default AiBlob;
