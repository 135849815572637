import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	Button,
	Box,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OpenContactButton from "./OpenContactButton";
import quickloopLogoLight from "../assets/logos/quickloop-light.svg";

const Header: React.FC = () => {
	const [mobileOpen, setMobileOpen] = useState(false);

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return;
			}
			setMobileOpen(open);
		};

	const scrollToSection = (id: string) => {
		const section = document.querySelector(id);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	const menuItems = [
		{ label: "Home", section: "#home" },
		{ label: "Services", section: "#services" },
		{ label: "Our Approach", section: "#our-approach" },
		{ label: "Portfolio", section: "#portfolio" },
		{ label: "Team", section: "#team" },
	];

	const drawer = (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List>
				{menuItems.map((item) => (
					<ListItem key={item.label} disablePadding>
						<ListItemButton
							onClick={() => scrollToSection(item.section)}
						>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider />
			<Box sx={{ textAlign: "center", py: 2 }}>
				<OpenContactButton />
			</Box>
		</Box>
	);

	return (
		<AppBar
			component="nav"
			sx={{ color: "black", backgroundColor: "white" }}
			elevation={1}
		>
			<Toolbar
				sx={{ justifyContent: "space-between", px: { xs: 2, md: 8 } }}
			>
				<a href="/" className="navbar-brand" style={{ flexGrow: 1 }}>
					<img
						src={quickloopLogoLight}
						alt="Quickloop"
						className="navbar-logo h-8 transition-all duration-300"
					/>
				</a>

				{/* Desktop Menu (Hidden on small screens) */}
				<Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
					{menuItems.map((item) => (
						<Button
							key={item.label}
							color="inherit"
							onClick={() => scrollToSection(item.section)}
						>
							{item.label}
						</Button>
					))}
					<OpenContactButton />
				</Box>

				{/* Hamburger Icon (Visible on small screens) */}
				<IconButton
					color="inherit"
					edge="end"
					sx={{ display: { xs: "block", md: "none" } }}
					onClick={toggleDrawer(true)}
				>
					<MenuIcon />
				</IconButton>

				{/* Mobile Drawer */}
				<Drawer
					anchor="right"
					open={mobileOpen}
					onClose={toggleDrawer(false)}
				>
					{drawer}
				</Drawer>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
