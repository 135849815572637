import React, { useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Typography,
	IconButton,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	SelectChangeEvent,
	Snackbar,
	Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LAMBDA_URL =
	"https://g8vr8b2607.execute-api.ap-south-1.amazonaws.com/Production/airtableContactHandler";

interface ContactDialogProps {
	open: boolean;
	handleClose: () => void;
}

const ContactDialog: React.FC<ContactDialogProps> = ({ open, handleClose }) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
		service: "",
	});
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success" as "success" | "error",
	});

	const handleInputChange = (
		event: React.ChangeEvent<
			HTMLInputElement | { name?: string; value: unknown }
		>
	) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({ ...prevData, [name!]: value }));
	};

	const handleDropDownChange = (event: SelectChangeEvent<string>) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({ ...prevData, [name!]: value }));
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!executeRecaptcha) {
			setSnackbar({
				open: true,
				message: "ReCAPTCHA not ready. Please try again later.",
				severity: "error",
			});
			return;
		}

		try {
			const token = await executeRecaptcha("submit");
			const response = await fetch(LAMBDA_URL, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ ...formData, token }),
			});

			if (response.status >= 200 && response.status < 300) {
				setSnackbar({
					open: true,
					message: "Form submitted successfully!",
					severity: "success",
				});
				handleClose();
				setFormData({
					name: "",
					email: "",
					phone: "",
					message: "",
					service: "",
				});
			} else {
				setSnackbar({
					open: true,
					message: "Failed to submit the form. Please try again.",
					severity: "error",
				});
			}
		} catch (error) {
			console.error("Error submitting form:", error);
			setSnackbar({
				open: true,
				message: "An error occurred. Please try again.",
				severity: "error",
			});
		}
	};

	const handleSnackbarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbar({ ...snackbar, open: false });
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="h6">Contact Us</Typography>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle>

				<form onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							fullWidth
							name="name"
							label="Name"
							variant="outlined"
							margin="normal"
							required
							value={formData.name}
							onChange={handleInputChange}
						/>
						<TextField
							fullWidth
							name="email"
							label="Email"
							type="email"
							variant="outlined"
							margin="normal"
							required
							value={formData.email}
							onChange={handleInputChange}
						/>
						<TextField
							fullWidth
							name="phone"
							label="Phone"
							type="tel"
							variant="outlined"
							margin="normal"
							required
							value={formData.phone}
							onChange={handleInputChange}
						/>
						<FormControl
							fullWidth
							margin="normal"
							variant="outlined"
						>
							<InputLabel>Service (Optional)</InputLabel>
							<Select
								name="service"
								value={formData.service}
								onChange={handleDropDownChange}
								label="Service (Optional)"
							>
								<MenuItem value="">None</MenuItem>
								<MenuItem value="Web and Mobile Development">
									Web and Mobile Development
								</MenuItem>
								<MenuItem value="AI">AI</MenuItem>
								<MenuItem value="DevOps">DevOps</MenuItem>
								<MenuItem value="Cloud">Cloud</MenuItem>
							</Select>
						</FormControl>
						<TextField
							fullWidth
							name="message"
							label="Message"
							multiline
							rows={4}
							variant="outlined"
							margin="normal"
							required
							value={formData.message}
							onChange={handleInputChange}
						/>
					</DialogContent>

					<DialogActions>
						<Button
							variant="outlined"
							onClick={handleClose}
							color="secondary"
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Submit
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbar.severity}
					sx={{ width: "100%" }}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default ContactDialog;
